import React, { useState, useEffect } from 'react';
import { Button, Form, FormGroup, Label, Input, Card, CardBody, CardHeader, Table, Spinner } from 'reactstrap';
import { getStrategy, placeOrder, getAllStrategiesData } from '../api';
import IntradayChart from './IntradayChart';
import dayjs from 'dayjs';
import StrategyComparisonTable from './StrategyComparisonTable';

function StrategySelector() {
  const [symbol, setSymbol] = useState('NIFTY 50');
  const [userIntuition, setUserIntuition] = useState(0);
  const [strategy, setStrategy] = useState(null);
  const [nearExpiryDate, setNearExpiryDate] = useState('');
  const [currentDate, setCurrentDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [currentTime, setCurrentTime] = useState(dayjs().format('HH:mm'));
  const [isLoading, setIsLoading] = useState(false);
  const [intradayData, setIntradayData] = useState(null);
  const [isLoadingAllStrategies, setIsLoadingAllStrategies] = useState(false);
  const [strategyComparisonData, setStrategyComparisonData] = useState(null);

  useEffect(() => {
    updateExpiryDates(symbol);
  }, [symbol]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { 
      timeZone: 'Asia/Kolkata',
      day: '2-digit',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };
    
    const formattedDate = new Intl.DateTimeFormat('en-IN', options).format(date);
    
    // Replace the space between date and time with a comma
    return formattedDate.replace(',', '').replace(' ', ', ');
  };

  const updateExpiryDates = (selectedSymbol) => {
    const today = new Date();
    let nearExpiry = new Date(today);
    let farExpiry = new Date(today);

    if (selectedSymbol === 'NIFTY 50') {
      nearExpiry.setDate(today.getDate() + ((4 + 7 - today.getDay()) % 7));
      farExpiry.setDate(nearExpiry.getDate() + 7);
    } else if (selectedSymbol === 'NIFTY BANK') {
      nearExpiry.setDate(today.getDate() + ((3 + 7 - today.getDay()) % 7));
      farExpiry.setDate(nearExpiry.getDate() + 7);
    }

    setNearExpiryDate(nearExpiry.toLocaleDateString('en-CA'));
    //setFarExpiryDate(farExpiry.toLocaleDateString('en-CA'));
  };

  const handleGetStrategy = async () => {
    setIsLoading(true);
    setIntradayData(null)
    setStrategyComparisonData(null)
    try {
      const result = await getStrategy(symbol, userIntuition, nearExpiryDate, currentDate, currentTime);

      if(result.intraday_data){
        const formattedIntradayData = result.intraday_data.map(d => ({
          ...d,
          date: dayjs(d.date).toISOString() // Convert to ISO string for consistent parsing
        }));
        setIntradayData(formattedIntradayData);
        delete result.intraday_data;
      }
      setStrategy(result);

      if(result && result.al_trend){
        handleGetAllStrategiesData(symbol, nearExpiryDate, result.last_price, result.ah_volatility, result.al_trend)
      }
      
    } catch (error) {
      console.error('Error fetching strategy:', error);
      alert('Failed to fetch strategy. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGetAllStrategiesData = async (symbol, nearExpiryDate, last_price, volatility, trend) => {
    setIsLoadingAllStrategies(true);
    try {
      const result = await getAllStrategiesData({
        symbol,
        expiryDate: nearExpiryDate,
        lastPrice: last_price,
        volatility: volatility,
        trend: trend
      });
      setStrategyComparisonData(result);
    } catch (error) {
      console.error('Error fetching all strategies data:', error);
      alert('Failed to fetch all strategies data. Please try again.');
    } finally {
      setIsLoadingAllStrategies(false);
    }
  };

  const renderStrikesArray = (strikes) => {
    return strikes.map((strike, index) => {
      const [action, option] = Object.entries(strike)[0];
      return (
        <div key={index}>
          {action}: {option}
        </div>
      );
    });
  };

  const renderStrategyTable = () => {
    if (!strategy) return null;

    return (
      <Table striped>
        <tbody>
          {Object.entries(strategy).map(([key, value]) => {
            if (key.includes('strikes')) {
              return (
                <tr key={key}>
                  <th scope="row">Strikes</th>
                  <td>{renderStrikesArray(value)}</td>
                </tr>
              );
            } else if (key.includes('date') || key.includes('time')) {
              return (
                <tr key={key}>
                  <th scope="row">{key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}</th>
                  <td>{formatDate(value)}</td>
                </tr>
              );
            } else {
              return (
                <tr key={key}>
                  <th scope="row">{key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}</th>
                  <td>{typeof value === 'number' ? value.toFixed(2) : String(value)}</td>
                </tr>
              );
            }
          })}
        </tbody>
      </Table>
    );
  };


  return (
    <div>
      <h5 className="mb-4">Selector</h5>
      <Form>
        <FormGroup>
          <Label for="symbolSelect">Select an index</Label>
          <Input 
            type="select" 
            name="select" 
            id="symbolSelect" 
            value={symbol} 
            onChange={(e) => setSymbol(e.target.value)}
          >
            <option value="NIFTY 50">NIFTY</option>
            <option value="NIFTY BANK">NIFTY BANK</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="nearExpiryDate">Near Expiry Date</Label>
          <Input
            type="date"
            name="nearExpiryDate"
            id="nearExpiryDate"
            value={nearExpiryDate}
            onChange={(e) => setNearExpiryDate(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="currentDate">Current Date</Label>
          <Input
            type="date"
            name="currentDate"
            id="currentDate"
            value={currentDate}
            onChange={(e) => setCurrentDate(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="currentTime">Current Time (IST)</Label>
          <Input
            type="time"
            name="currentTime"
            id="currentTime"
            value={currentTime}
            onChange={(e) => setCurrentTime(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="intuitionSlider">User Intuition: {userIntuition}</Label>
          <Input
            type="range"
            name="range"
            id="intuitionSlider"
            min="-1"
            max="1"
            step="0.1"
            value={userIntuition}
            onChange={(e) => setUserIntuition(parseFloat(e.target.value))}
          />
        </FormGroup>
        <Button color="primary" onClick={handleGetStrategy} disabled={isLoading}>
          {isLoading ? <Spinner size="sm" /> : 'Get Strat'}
        </Button>
      </Form>

      {strategy && (
        <Card className="mt-4">
          <CardHeader>Recommended Strategy</CardHeader>
          <CardBody>
            {renderStrategyTable()}
            {strategyComparisonData && (
              <StrategyComparisonTable 
                data={strategyComparisonData} 
                recommendedStrategy={strategy.am_strategy}
              />
            )}
            {isLoadingAllStrategies && (
              <div className="text-center mt-3">
                <Spinner color="primary" /> Loading all strategies data...
              </div>
            )}
          </CardBody>
        </Card>
      )}

      {intradayData && <IntradayChart data={intradayData} />}
    </div>
  );
}

export default StrategySelector;