import React from 'react';
import { Line } from 'react-chartjs-2';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const IntradayChart = ({ data }) => {
  if (!data || data.length === 0) return null;

  const convertToIST = (dateString) => {
    return dayjs(dateString).tz('Asia/Kolkata').format('HH:mm');
  };

  const chartData = {
    labels: data.map(d => convertToIST(d.date)),
    datasets: [
      {
        label: 'Price',
        data: data.map(d => d.close),
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Price Movement (IST)',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Time (IST)',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Price',
        },
      },
    },
  };

  return (
    <Card className="mt-4">
      <CardHeader>Price Chart (IST)</CardHeader>
      <CardBody>
        <Line data={chartData} options={options} />
      </CardBody>
    </Card>
  );
};

export default IntradayChart;