import React, { useState } from 'react';
import { Table, Button, Input, Label, FormGroup } from 'reactstrap';
import { placeOrder } from '../api';

const StrategyComparisonTable = ({ data, recommendedStrategy, symbol }) => {
  const [quantities, setQuantities] = useState({});
  const [productType, setProductType] = useState('NRML');
  const [responses, setResponses] = useState({});

  if (data.error) {
    return <div>{data.error}</div>;
  }

  const handleQuantityChange = (strategy, quantity) => {
    setQuantities({ ...quantities, [strategy]: quantity });
  };

  const handlePlaceOrder = async (strategy) => {
    try {
      const orderDetails = {
        symbol: symbol,
        strategy: strategy,
        strikes: data[strategy].strikes.map(strike => ({
          ...strike,
          quantity: quantities[strategy] || 1
        })),
        product_type: productType
      };
      const result = await placeOrder(orderDetails);
      if(result.error){
        setResponses({
            ...responses,
            [strategy]: { type: 'error', message: 'Failed to place order.'+result.error }
          });
      }
      else{
        setResponses({
            ...responses,
            [strategy]: { type: 'success', message: `Order placed successfully. Strategy ID: ${result.strategy_id}` }
          });
      }
      
    } catch (error) {
      console.error('Error placing order:', error);
      setResponses({
        ...responses,
        [strategy]: { type: 'error', message: 'Failed to place order. Please try again.' }
      });
    }
  };

  return (
    <>
      <FormGroup>
        <Label for="productType">Product Type</Label>
        <Input
          type="select"
          name="productType"
          id="productType"
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
        >
          <option value="NRML">NRML (Normal)</option>
          <option value="MIS">MIS (Intraday)</option>
          <option value="CNC">CNC (Delivery)</option>
        </Input>
      </FormGroup>
      <Table striped>
        <thead>
          <tr>
            <th>Strategy</th>
            <th>Strikes</th>
            <th>Premiums</th>
            <th>Total Margin</th>
            <th>Quantity</th>
            <th>Action</th>
            <th>Response</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(data).map(([strategy, strategyData]) => (
            <tr key={strategy} className={strategy === recommendedStrategy ? 'bg-warning' : ''}>
              <td>{strategy}{strategy === recommendedStrategy ? " **" : ""}</td>
              <td>{strategyData.strikes.map(strike => `${Object.keys(strike)[0]}: ${Object.values(strike)[0]} (${strike.trading_symbol})`).join(', ')}</td>
              <td>{strategyData.premiums.map(premium => premium.toFixed(2)).join(', ')}</td>
              <td>{strategyData.total_margin.toFixed(2)}</td>
              <td>
                <Input
                  type="number"
                  value={quantities[strategy] || ''}
                  onChange={(e) => handleQuantityChange(strategy, e.target.value)}
                  placeholder="Quantity"
                />
              </td>
              <td>
                <Button color="primary" size="sm" onClick={() => handlePlaceOrder(strategy)}>
                  Place Order
                </Button>
              </td>
              <td>
                {responses[strategy] && (
                  <div className={`text-${responses[strategy].type === 'success' ? 'success' : 'danger'}`}>
                    {responses[strategy].message}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default StrategyComparisonTable;